import { UseQueryResult, useQuery, UseQueryOptions } from 'react-query';
import useApiClient from './use-api-client';
import { CaseReport } from '../entities/case-report.entity';
import MyActivityService, { LastOpenedCaseReportsQuery } from '../api/services/my-activity.service';

export const LAST_OPENED_CASE_REPORTS_QUERY_KEY = 'last-opened-case-reports';

const useLastOpenedCaseReportsQuery = (
  params: LastOpenedCaseReportsQuery,
  options?: UseQueryOptions<CaseReport[]>
): UseQueryResult<CaseReport[]> => {
  const apiClient = useApiClient();

  return useQuery<CaseReport[]>(
    [LAST_OPENED_CASE_REPORTS_QUERY_KEY, params],
    () => new MyActivityService(apiClient).getLastOpenedCaseReports(params),
    options
  );
};

export default useLastOpenedCaseReportsQuery;
