import React from 'react';
import { useTranslation } from 'next-i18next';
import { Card, CardBody, CardHeader, CardTitle, Spinner } from 'reactstrap';
import formatDuration from '../../utils/format-duration/format-duration';
import useDashboardStatsQuery, {
  DashboardStats as DashboardStatsType,
} from '../../model/hooks/use-dashboard-stats-query';

const getComparisonIndicatorModifier = (
  currentValue: number,
  previousValue: number,
  higherIsBetter: boolean
) => {
  if (currentValue === previousValue) {
    return 'same';
  }
  const direction = currentValue > previousValue ? 'up' : 'down';
  const color =
    (currentValue > previousValue && higherIsBetter) ||
    (currentValue < previousValue && !higherIsBetter)
      ? 'green'
      : 'red';
  return `${direction}-${color}`;
};

const DashboardStats: React.FC<{}> = () => {
  const { t } = useTranslation();

  const { data } = useDashboardStatsQuery();

  const renderCard = (title: string, value: string, comparisonIndicatorModifier: string) => {
    return (
      <div className="text-center" style={{ minWidth: 360, flex: 1 }}>
        <h6>{title}</h6>
        <div className="card bg-light border-0 mb-0">
          <div className="card-body">
            <div className="position-relative d-inline-block">
              <h1 className="d-inline-block" style={{ marginBottom: 0 }}>
                {value}
              </h1>
              <span
                className={`comparison-indicator comparison-indicator--${comparisonIndicatorModifier}`}
              />
            </div>
            <div>
              <small>{t('DASHBOARD_STATS.PERIOD')}</small>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStats = (stats: DashboardStatsType) => {
    return (
      <div className="d-flex flex-wrap" style={{ gap: 24 }}>
        {renderCard(
          `${t('DASHBOARD_STATS.MINUTES_TO_RESOLVE')}`,
          formatDuration(stats.currentWeek.avgMinutesToResolve),
          getComparisonIndicatorModifier(
            stats.currentWeek.avgMinutesToResolve,
            stats.lastWeek.avgMinutesToResolve,
            false
          )
        )}
        {renderCard(
          t('DASHBOARD_STATS.MINUTES_TO_REVIEW_FOLLOW_UP'),
          formatDuration(stats.currentWeek.avgMinutesToReviewFollowUp),
          getComparisonIndicatorModifier(
            stats.currentWeek.avgMinutesToReviewFollowUp,
            stats.lastWeek.avgMinutesToReviewFollowUp,
            false
          )
        )}
        {renderCard(
          t('DASHBOARD_STATS.CSAT'),
          Number(stats.currentWeek.avgCsat).toFixed(2).toString(),
          getComparisonIndicatorModifier(stats.currentWeek.avgCsat, stats.lastWeek.avgCsat, true)
        )}
      </div>
    );
  };

  return (
    <Card className="w-100">
      <CardHeader>
        <CardTitle className="h5 mb-0">{t('HOME.OVERVIEW_SECTION.TITLE')}</CardTitle>
      </CardHeader>
      <CardBody>
        {data != null ? (
          renderStats(data)
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: 100 }}
          >
            <Spinner color="dark" />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default DashboardStats;
