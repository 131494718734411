import React from 'react';
import { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { Container } from 'reactstrap';
import withAuth, { WithAuthProps } from '../utils/with-auth/with-auth';
import Dashboard from '../layouts/dashboard';
import getServerSideTranslations from '../utils/get-server-side-translations/get-server-side-translations';
import PageMeta from '../components/page-meta/page-meta.component';
import DashboardStats from '../components/dashboards-stats/dashboard-stats.component';
import Gate from '../components/gate/gate.component';
import { Action, ProtectedResource } from '../model/entities/role.entity';
import LastOpenedCaseReportsList from '../components/last-opened-case-reports-list/last-opened-case-reports-list.component';

const Home: NextPage<WithAuthProps> = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="p-0">
      <PageMeta title={t('HOME.META.TITLE')} />
      <h1 className="h3 mb-3">{t('HOME.PAGE_TITLE')}</h1>
      <p>{t('HOME.DESCRIPTION')}</p>
      <Gate config={[ProtectedResource.Stats, Action.Read]}>
        <div style={{ height: 40 }} />
        <DashboardStats />
      </Gate>
      <Gate config={[ProtectedResource.CaseReport, Action.List]}>
        <LastOpenedCaseReportsList title={t('HOME.LAST_OPENED_CASE_REPORTS.TITLE')} />
      </Gate>
    </Container>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await getServerSideTranslations(locale, true)),
    },
  };
};

// @ts-ignore
Home.getLayout = (page: React.ReactElement) => <Dashboard>{page}</Dashboard>;

export default withAuth(Home, []);
