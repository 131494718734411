import { DiseaseCategory } from '../../model/entities/case-report.entity';
import assertUnreachable from '../assert-unreachable/assert-unreachable';

const getDiseaseCategoryNameTranslationKey = (category: DiseaseCategory) => {
  switch (category) {
    case DiseaseCategory.ErectileDysfunction:
      return 'DISEASE_CATEGORIES.ERECTILE_DYSFUNCTION';
    case DiseaseCategory.HairLoss:
      return 'DISEASE_CATEGORIES.HAIR_LOSS';
    case DiseaseCategory.Skincare:
      return 'DISEASE_CATEGORIES.SKINCARE';
    case DiseaseCategory.WeightLoss:
      return 'DISEASE_CATEGORIES.WEIGHT_LOSS';
    case DiseaseCategory.PrematureEjaculation:
      return 'DISEASE_CATEGORIES.PREMATURE_EJACULATION';
    default:
      return assertUnreachable(category);
  }
};

export default getDiseaseCategoryNameTranslationKey;
