import BaseService from './base-service';
import { PaginatedResponse } from '../../types/paginated-response';
import { AnonymizedCaseReport } from '../../entities/anonymized-case-report.entity';
import { CaseReportsListQuery } from '../../types/case-reports-list-query';
import { AnalyticsStats } from '../../entities/analytics-stats.entity';
import { CaseReportActivityStats } from '../../types/case-report-activity-stats';

export interface CaseReportActivityStatsListQuery {
  numberOfDaysSinceLastOrder?: number;
  numberOfDaysSinceLastFollowUp?: number;
}

class AnalyticsService extends BaseService {
  getAnonymizedCaseReports(
    params: CaseReportsListQuery = { page: 1, size: 1000 }
  ): Promise<PaginatedResponse<AnonymizedCaseReport>> {
    return this._apiClient
      .get('/api/rest/v1/analytics/case-reports', { params: { submitted: true, ...params } })
      .then(response => response.data);
  }

  getTypeformIncompleteResponsesAmount(params: {
    formIds: string[];
    dateFrom?: string;
    dateTo?: string;
  }): Promise<number> {
    return this._apiClient
      .get('/api/rest/v1/typeform/incomplete-responses/count', { params })
      .then(response => response.data);
  }

  getAnalyticsStats(params: { dateFrom: Date; dateTo: Date }): Promise<AnalyticsStats> {
    return this._apiClient
      .get('/api/rest/v1/analytics/stats', { params })
      .then(response => response.data);
  }

  getCaseReportActivityStats(
    params: CaseReportActivityStatsListQuery
  ): Promise<CaseReportActivityStats[]> {
    return this._apiClient
      .get('/api/rest/v1/analytics/case-report-activity-stats', { params })
      .then(response => response.data);
  }
}

export default AnalyticsService;
