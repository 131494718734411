import React from 'react';
import utcStartOfDay from '../../utils/utc-start-of-day/utc-start-of-day';
import utcAddDays from '../../utils/utc-add-days/utc-add-days';
import useLastOpenedCaseReportsQuery from '../../model/hooks/use-last-opened-case-reports-query';
import useCaseReportsTable, {
  CaseReportsTableColumnName,
} from '../../hooks/use-case-reports-table/use-case-reports-table';
import { CaseReport } from '../../model/entities/case-report.entity';
import ReactTableBlock from '../react-table-block/react-table-block.component';

const LAST_OPENED_CASE_REPORTS_SIZE = 10;

const COLUMN_NAMES: CaseReportsTableColumnName[] = [
  CaseReportsTableColumnName.NeedsAttention,
  CaseReportsTableColumnName.Patient,
  CaseReportsTableColumnName.SubmittedAt,
  CaseReportsTableColumnName.DiseaseCategory,
  CaseReportsTableColumnName.Assignee,
  CaseReportsTableColumnName.Status,
  CaseReportsTableColumnName.ManageButtons,
];

interface LastOpenedCaseReportsListProps {
  title: string;
}

const LastOpenedCaseReportsList: React.FC<LastOpenedCaseReportsListProps> = ({ title }) => {
  const now = React.useRef(new Date()).current;
  const dateFrom = utcStartOfDay(utcAddDays(now, -7));

  const {
    data: lastOpenedCaseReports = [],
    isLoading,
    isFetching,
  } = useLastOpenedCaseReportsQuery({
    size: LAST_OPENED_CASE_REPORTS_SIZE,
    dateFrom,
  });

  const caseReportsTableProps = useCaseReportsTable(COLUMN_NAMES);

  return (
    <ReactTableBlock<CaseReport>
      {...caseReportsTableProps}
      title={title}
      data={lastOpenedCaseReports}
      isLoading={isLoading}
      isFetching={isFetching}
      hidePagination
    />
  );
};

export default LastOpenedCaseReportsList;
