import BaseService from './base-service';
import { CaseReport } from '../../entities/case-report.entity';

export interface LastOpenedCaseReportsQuery {
  size?: number;
  dateFrom?: Date;
  dateTo?: Date;
}

class MyActivityService extends BaseService {
  getLastOpenedCaseReports(params: LastOpenedCaseReportsQuery): Promise<CaseReport[]> {
    return this._apiClient
      .get('/api/rest/v1/my-activity/last-opened-case-reports', { params })
      .then(response => response.data);
  }
}

export default MyActivityService;
