import * as React from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import useApiClient from './use-api-client';
import AnalyticsService from '../api/services/analytics.service';
import { AnalyticsStats } from '../entities/analytics-stats.entity';
import utcStartOfDay from '../../utils/utc-start-of-day/utc-start-of-day';
import utcAddDays from '../../utils/utc-add-days/utc-add-days';
import utcEndOfDay from '../../utils/utc-end-of-day/utc-end-of-day';

export const DASHBOARD_STATS_QUERY_KEY = 'dashboard-stats';

export interface DashboardStats {
  currentWeek: AnalyticsStats;
  lastWeek: AnalyticsStats;
}

const useDashboardStatsQuery = (): UseQueryResult<DashboardStats> => {
  const apiClient = useApiClient();

  const today = React.useRef(new Date()).current;

  const currentPeriodStart = utcStartOfDay(utcAddDays(today, -7));
  const currentPeriodEnd = utcEndOfDay(utcAddDays(today, -1));

  const previousPeriodStart = utcStartOfDay(utcAddDays(today, -14));
  const previousPeriodEnd = utcEndOfDay(utcAddDays(today, -8));

  return useQuery<DashboardStats>(DASHBOARD_STATS_QUERY_KEY, async () => {
    const service = new AnalyticsService(apiClient);

    const [currentPeriodStats, previousPeriodStats] = await Promise.all([
      service.getAnalyticsStats({ dateFrom: currentPeriodStart, dateTo: currentPeriodEnd }),
      service.getAnalyticsStats({ dateFrom: previousPeriodStart, dateTo: previousPeriodEnd }),
    ]);

    return {
      currentWeek: currentPeriodStats,
      lastWeek: previousPeriodStats,
    };
  });
};

export default useDashboardStatsQuery;
