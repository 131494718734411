const getDurationComponents = (totalMinutes: number) => {
  const days = Math.floor(totalMinutes / (60 * 24));
  const hours = Math.floor((totalMinutes - days * (60 * 24)) / 60);
  const minutes = Math.floor(totalMinutes - days * (60 * 24) - hours * 60);

  return {
    days,
    hours,
    minutes,
  };
};

const formatDuration = (totalMinutes: number): string => {
  const { days, hours, minutes } = getDurationComponents(totalMinutes);
  return `${days}d ${hours}h ${minutes}m`;
};

export default formatDuration;
